import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './tariff-premium.styled';
import { TariffHeader } from '../header/tariff-header';
import { TariffWrapper } from '../wrapper/tariff-wrapper';
import { TariffBadge } from '../badge/tariff-badge';
import { TariffPremiumPrice } from './price/tariff-premium-price';
import { TariffNote } from '../note/tariff-note';
import { TariffPremiumButton } from './button/tariff-premium-button';
import { TariffFeaturesPremium } from '../features/premium/tariff-features-premium';

type TariffPremiumProps = {
    period: 'yearly' | 'monthly';
};

export const TariffPremium: React.FC<TariffPremiumProps> = observer(({ period }) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <TariffWrapper>
                <TariffHeader
                    name={t(`blocks.pricing.premium.name`)}
                    badge={
                        period === 'yearly' ? <TariffBadge content={t(`blocks.pricing.premium.twoMonthsFree`)} /> : null
                    }
                />

                <TariffPremiumPrice period={period} />

                <TariffFeaturesPremium />

                <TariffPremiumButton period={period} />

                <TariffNote>{t('blocks.pricing.premium.note')}</TariffNote>
            </TariffWrapper>
        </Wrapper>
    );
});
