import React from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper, Value, Suffix } from './tariff-exclusive-price.styled';
import { ValueString } from '../../../../app/value/string/value-string';

type TariffExclusivePriceProps = {
    period: 'yearly' | 'monthly';
};

export const TariffExclusivePrice: React.FC<TariffExclusivePriceProps> = ({ period }) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            {period === 'monthly' && (
                <Value crossed>
                    <ValueString text={'4 457 Kč'} mode={'pure'} locked />
                </Value>
            )}
            {period === 'yearly' && (
                <Value crossed>
                    <ValueString text={'3 457 Kč'} mode={'pure'} locked />
                </Value>
            )}
            <Value>
                {period === 'monthly' ? (
                    <ValueString text={'357 Kč'} mode={'pure'} locked />
                ) : (
                    <ValueString text={'457 Kč'} mode={'pure'} locked />
                )}
            </Value>

            <Suffix>&nbsp;/ {t(`blocks.pricing.common.${period}`)}</Suffix>
        </Wrapper>
    );
};
