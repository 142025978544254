import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Button } from '../../../../app/form/button/button';
import { useRootStore } from '../../../../../providers/root-store-provider';
import { LookupKeyEnum } from '../../../../../defs/api';

type TariffPremiumButtonProps = {
    period: 'yearly' | 'monthly';
};

export const TariffPremiumButton: React.FC<TariffPremiumButtonProps> = observer(({ period }) => {
    const { t } = useTranslation();
    const rootStore = useRootStore();
    const { authStore } = rootStore;
    const { stripeStore } = rootStore;
    const isLoading = authStore.loading || stripeStore.loading;

    const loggedUser = rootStore.cookieService.hasJwtToken();

    const lookupKey = period === 'yearly' ? LookupKeyEnum.PremiumYearlyCzk : LookupKeyEnum.PremiumMonthlyCzk;

    if (loggedUser) {
        // Logged user with active premium tariff
        if (authStore.userHasPremiumTariff) {
            return (
                <Button loading={isLoading} variant="default" gradient shadow size="large" disabled>
                    {t('blocks.pricing.premium.action.isActive')}
                </Button>
            );
        }

        return (
            <Button
                onClick={async () => {
                    await stripeStore.createCheckoutSession(lookupKey);
                    await authStore.fetchUserProfile();
                }}
                loading={isLoading}
                variant="default"
                gradient
                shadow
                size="large"
            >
                {t('blocks.pricing.premium.action.getPremium')}
            </Button>
        );
    }

    // Not logged user => Sign up button (= open modal)
    return (
        <Button
            onClick={() => {
                authStore.authDialogStore.setSignupActive(lookupKey);
            }}
            variant="default"
            gradient
            shadow
            size="vlarge"
        >
            {t('blocks.pricing.premium.action.signUp')}
        </Button>
    );
});
